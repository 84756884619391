import axios from 'axios';
import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import Dashboard from '../../Dashboard/Dashboard/Dashboard';
import './AddMessages.css'

const AddMessages = () => {
    const { user, loading } = useAuth();
    const [showSpin, setShowSpin] = useState(true);

    const [productData, setProductData] = useState({
        name: '',
        session: '',
        imageUrl: '',
        msg: ''
    });

    const history = useHistory();

    const handleSubmit = e => {
        console.log(productData);
        const url = `https://bornali-server-side.vercel.app/messages`;

        fetch(url, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(productData)
        })
            .then(res => res.json())
            .then(data => {
                                console.log(data)
                                if(data.insertedId) {
                                    alert('Message added successfully');
                                    // reset();
                                    console.log('Details added successfully');
                                    // history.push('/home');
                                }
                            })
                            .catch(error => {
                                console.error('Error:', error);
                            });

    }
    const handleProductImageChange = event => {
        setShowSpin(false);
        console.log(event.target.files[0]);
        const imageData = new FormData();
        imageData.set('key', '2db9680356ab7afbbbca29c97ded20be');
        imageData.append('image', event.target.files[0]);
        console.log("imageData", imageData.values())
        console.log("imageData.getAll image", imageData.getAll("image"))
        axios.post('https://api.imgbb.com/1/upload',
            imageData)
            .then(function (response) {
                const newProductData = { ...productData };
                newProductData.imageUrl = response.data.data.display_url;
                setProductData(newProductData);
                setShowSpin(true);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const handleProductPropertyChange = event => {
        const newProductData = { ...productData };
        newProductData[event.target.name] = event.target.value.trim();
        setProductData(newProductData);
    }

    return (
        <div className="row">
                <div className="w-75 m-auto p-3 mt-5 mb-3 add-service">
                    <h2 className="mb-3 heading fw-normal">Add President's Message</h2>
                    <form>
                            <hr></hr>
                            {/* <div className='d-flex align-items-center'> */}
                                <input type="file" className='w-75 mb-1 form-control' onChange={handleProductImageChange}/>
                                
                            {/* </div> */}
                            {!showSpin && <div><Spinner></Spinner><p className="text-center">Please Wait.! Image Uploading...</p></div>}

                            <input placeholder="President's Name" name="name" className='w-75 mb-1 form-control' onChange={handleProductPropertyChange} required/>

                            <input placeholder="Session i.e 2016-2017" name="session" className='w-75 mb-1 form-control' onChange={handleProductPropertyChange} required/>

                            <textarea placeholder="President's Message" name="msg" className='w-75 mb-1 form-control' onChange={handleProductPropertyChange} required/>
                            
                            <Button className='text-white mt-3' variant="primary" as="input" type="reset" value="Add Message" block onClick={handleSubmit} />
                        </form>
            </div>
        </div>
    );
};

export default AddMessages;