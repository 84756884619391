import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import useAuth from '../../../hooks/useAuth';
import Dashboard from '../../Dashboard/Dashboard/Dashboard';
import MakeAdmin from '../MakeAdmin/MakeAdmin';
import MakeLibrarian from '../MakeLibrarian/MakeLibrarian';

const MakeAdministrator = () => {
    const { user, loading } = useAuth();
    return (
        <div>
        <div className="row">
            <div className="col-2">
                <Dashboard></Dashboard>
            </div>
            <div className="col-10">
                <h2 style={{backgroundColor: 'rgb(170, 212, 247)', fontFamily: '"Dosis", sans-serif', color: 'rgb(59, 96, 133)'}} className="p-3 pb-4 text-center fw-bold mb-5">{user.displayName}'s dashboard</h2>

                <div className="w-75 m-auto mt-5 border rounded mb-3 border-info add-service">
                    <Tabs defaultActiveKey="makeAdmin" id="uncontrolled-tab-example">
                        <Tab eventKey="makeAdmin" title="Make Admin" className="bg-white">
                            <MakeAdmin></MakeAdmin>
                        </Tab>
                        <Tab eventKey="makeLibrarian" title="Make Librarian" className="bg-white">
                            <MakeLibrarian></MakeLibrarian>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
        </div>
    );
};

export default MakeAdministrator;