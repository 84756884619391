import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import useAuth from '../../../hooks/useAuth';
import Dashboard from '../Dashboard/Dashboard';
import './JobPostForm.css'

const JobPostForm = () => {
    const { user, loading } = useAuth();
    const { register, formState: { errors }, handleSubmit, reset } = useForm();

    const onSubmit = data => {
        console.log(data);
        axios.post('https://bornali-server-side.vercel.app/jobs', data)
            .then(res => {
                if (res.data.insertedId) {
                    alert('Job added successfully');
                    reset();
                }
            })
    }

    var today = new Date();
    var date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear();
    return (
        <div>
            <div className="row">
                <div className="col-1 pe-0 me-0">
                    <Dashboard></Dashboard>
                </div>
                <div className="col-11 ps-0">
                    {user.displayName ? 
                        <h2 style={{backgroundColor: 'rgb(170, 212, 247)', fontFamily: '"Dosis", sans-serif', color: 'rgb(59, 96, 133)'}} className="p-3 pb-4 text-center fw-bold mb-5">
                        {user.displayName}'s dashboard</h2> :
                        <h2 style={{backgroundColor: 'rgb(170, 212, 247)', fontFamily: '"Dosis", sans-serif', color: 'rgb(59, 96, 133)'}} className="p-3 pb-4 text-center fw-bold mb-5">
                        My dashboard</h2>

                    }
                    <div className="w-50 mx-auto p-3 border rounded mb-3 border-info add-service">
                        <h1 className="mb-3 heading fw-normal">Job Posting Form</h1>
                        <form onSubmit={handleSubmit(onSubmit)} className=''>
                            <input {...register("title", { required: true })} placeholder="Job Title" className='w-75 padd'/>
                            {errors.title?.type === 'required' && "Job title is required"}

                            <textarea defaultValue="" {...register("context")} placeholder="Job Context (optional)" className='w-75 padd'/>
                               
                            <textarea defaultValue="" {...register("responsibilities")} placeholder="Job Responsibilities (optional)" className='w-75 padd'/>

                            <textarea defaultValue="" {...register("education")} placeholder="Educational Requirements (optional)" className='w-75 padd'/>

                            <textarea defaultValue="" {...register("experience")} placeholder="Experience Requirements (optional)" className='w-75 padd'/>

                            <textarea defaultValue="" {...register("additional")} placeholder="Additional Requirements (optional)" className='w-75 padd'/>

                            <textarea defaultValue="" {...register("location")} placeholder="Job location (optional)" className='w-75 padd'/>

                            <textarea defaultValue="" {...register("salary")} placeholder="Salary (optional)" className='w-75 padd'/>

                            <input defaultValue={user.displayName} {...register("postedBy")} className='w-75 padd' readOnly/>

                            <input defaultValue={date} {...register("dated")} className='w-75 padd' readOnly/>
                                    
                            <input type="submit" className="btn text-light heading btn-lg w-50 fw-normal" style={{backgroundColor: 'rgb(59, 96, 133)'}} value="Post Job"/>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobPostForm;