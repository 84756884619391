import React from 'react';
import { useForm } from 'react-hook-form';
import useAuth from '../../../hooks/useAuth';
import Dashboard from '../../Dashboard/Dashboard/Dashboard';

const MakeLibrarian = () => {
    const { user, loading } = useAuth();
    const { register, formState: { errors }, handleSubmit, reset } = useForm();

    const onSubmit = data => {
        // const user = { email };
        fetch('https://bornali-server-side.vercel.app/users/librarian', {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(data => {
                if (data.modifiedCount) {
                    alert('Made Librarian Successfully');
                    console.log(data);
                    reset();
                }
            })

        // e.preventDefault()
    }

    return (
        <div className="row">
            <div className="w-75 m-auto p-3 rounded mb-3 add-service">
                <h1 className="mb-3 heading fw-normal">Make Librarian</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input {...register("email", { required: true })} placeholder="Email" />
                    {errors.title?.type === 'required' && "Email is required"}
                            
                    <input type="submit" className="btn text-light heading btn-lg w-25 fw-normal" style={{backgroundColor: 'rgb(59, 96, 133)'}} value="Make Librarian"/>
                </form>
            </div>
        </div>
    );
};

export default MakeLibrarian;